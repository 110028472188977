.content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: var(--background-primary);

  padding: 1rem;

  > form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    > fieldset {
      margin-top: 1rem;

      &.actions {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > div {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
        }
      }
    }
  }
}
