.content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: var(--background-primary);

  padding: 1rem;

  > form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    > div:first-child {
      flex: 1 1 auto;
    }

    > div:nth-child(2) {
      flex: 0 0 auto;
      margin-inline-start: 1.5rem;
    }

    > div:nth-child(n + 3) {
      flex: 1 1 100%;
    }

    > fieldset {
      flex: 1 1 100%;
      margin-top: 1rem;

      &.actions {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > div {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
        }
      }
    }
  }
}
