.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 2rem;

  .stat,
  .leaderboard {
    flex: 1 1 33%;

    div:has(> h1) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
    }

    h1 {
      font-size: 1rem;
      font-weight: 600;
      margin-block-end: 2rem;
      color: var(--secondary);
    }
  }

  .stat {
    div:has(> h1) > div {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 0.5rem;

      h3 {
        margin-block-end: 0.5rem;
      }

      dl {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-block-end: 0;
        row-gap: 0.5rem;

        dt,
        dd {
          margin-bottom: 0;

          * {
            font-size: 0.75rem;
            margin-bottom: 0;
            color: #c3c7e3;
            font-weight: 600;
          }
        }

        dt {
          flex: 0 0 50%;
        }

        dd {
          flex: 0 0 50%;
          text-align: end;
        }
      }
    }
  }

  .leaderboard {
    div:has(> h1) {
      height: 100%;
    }

    div:has(> h1) > ol {
      padding: 0;
      flex: 1 1 auto;
      list-style: decimal;

      margin-bottom: 0;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-between;

      > li {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;

        * {
          margin: 0;
          flex: 1 1 auto;

          &:last-child {
            text-align: end;
          }
        }

        &::before {
          content: "# " counter(list-item);
          counter-increment: list-item;
          flex: 0 0 auto;
          margin-right: 1rem;
        }

        &:first-child {
          font-weight: 600;
          font-size: 24px;

          * {
            font-weight: 600;
            font-size: 24px;
          }
        }

        &:not(:first-child) {
          &::before {
            font-weight: 600;
            color: #c3c7e3;
            font-size: 0.75rem;
          }

          * {
            font-weight: 600;
            color: #c3c7e3;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
