.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--background-secondary);

  > div {
    margin-top: -50vh;
    text-align: center;

    > .login {
      > div {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 3rem;

        > div:first-child {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;

          > div {
            width: 9rem;
            height: 6rem;
            border-radius: 1rem;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("/assets/logo8.png");
            margin-bottom: 1rem;
          }

          > h2 {
            margin: 0;
          }
        }

        > div:last-child {
          > .twitchButton {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #6441a5;

            &:hover {
              background-color: #4b2e83;
              box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.25);
            }

            & * {
              font-size: 1rem;
            }

            > span {
              color: white;
            }
          }
        }

        &:before,
        &:after {
          content: none;
        }
      }
    }
  }
}
