.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--background-secondary);

  > .card {
    margin-top: -50vh;
    text-align: center;

    > div {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 3rem;

      > div {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        > div:first-child {
          width: 9rem;
          height: 6rem;
          border-radius: 1rem;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("/assets/logo8.png");
          margin-bottom: 1rem;
        }

        > div:last-child {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;

          > svg {
            font-size: 2rem;
          }

          > span {
            font-size: 1.5rem;
          }
        }
      }

      &:before,
      &:after {
        content: none;
      }
    }
  }
}
