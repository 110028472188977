.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 0.5rem;

  .card {
    &:hover {
      border-color: var(--primary);

      * {
        color: var(--primary);
      }
    }

    > div {
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      a {
        padding: 1.5rem;
        flex: 1 1 auto;
      }

      button {
        padding: 1rem;
        flex: 0 0 auto;
        font-size: 1.25rem;
        margin-inline-end: 0.5rem;
      }

      &:before,
      &:after {
        content: none;
      }
    }
  }
}
