.container {
  flex: 0 0 350px;
  padding: 3rem 2rem;
  background-color: var(--background-primary);

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  > div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    > div {
      width: 9rem;
      height: 6rem;
      border-radius: 1rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("/assets/logo8.png");
    }

    h3 {
      letter-spacing: 0.2rem;
    }
  }

  > ul {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 0.5rem;
  }

  ul {
    padding: 0;
    list-style: none;
    width: 100%;

    li {
      a,
      button {
        align-items: center;
        color: #495057;
        text-decoration: none;
        display: flex;
        padding: 1rem;
        overflow: hidden;
        border-radius: 0.75rem;
        background-color: transparent;
        border: none;
        width: 100%;
        font-size: 1rem;

        &.active {
          color: #ffffff;
          background-color: var(--primary);

          span {
            color: #ffffff;
          }

          &:active {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }

        &.subActive {
          color: var(--secondary);
          background-color: var(--tertiary-active);

          span {
            color: var(--secondary);
          }

          &:active {
            color: var(--secondary);
          }
        }

        &:hover {
          cursor: pointer;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        &:active {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }

      &:has(> ul) {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 0.5rem;

        > ul {
          overflow: hidden;
          border-radius: 0.75rem;
          background-color: var(--tertiary);

          a,
          button {
            border-radius: 0;
          }
        }
      }
    }
  }
}
