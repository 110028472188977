.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 auto;

  > .header {
    flex: 0 0 auto;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;

    > h2 {
      display: flex;
      justify-self: flex-start;
      font-size: 1.5rem;
      color: var(--secondary);
    }

    > div {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
    }
  }

  > .content {
    flex: 1 1 auto;
    min-height: 20rem;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
      height: 100%;
    }
  }
}
