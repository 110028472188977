.container {
  min-height: 100vh;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  > div {
    flex: 1 1 auto;
    padding: 2rem;
    background-color: var(--background-secondary);

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 1rem;

    > header {
      flex: 0 0 50px;

      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      width: 100%;
      max-width: 1200px;
      margin: auto;

      > div {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        overflow: hidden;

        &:hover,
        &.dropdownOpen {
          cursor: pointer;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        .profile {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-size: cover;
          background-color: gray;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    > main {
      flex: 1 1 auto;

      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 4rem;

      width: 100%;
      max-width: 1200px;
      margin: auto;
    }
  }
}

.progress {
  position: fixed;
  top: -0.6rem;
  left: 0;
  right: 0;
}
