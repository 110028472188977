@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

:root {
  /* root Theme */
  /* --text: #5d5c6d;
  --text-secondary: #5d5c6de0
  --primary: #5e67fe;
  --secondary: #868cda;
  --tertiary: #f5f6ff;
  --tertiary-active: #e2e2ff;
  --background-primary: #ffffff;
  --background-secondary: #f9f9fb;
  --hr: rgba(255, 255, 255, 0.5); */

  /* twitch Theme */
  --text: #ffffff;
  --text-secondary: #ffffffe0;
  --primary: #9147ff;
  --secondary: #ffffff;
  --tertiary: #9147ff46;
  --tertiary-active: #9751ff81;
  --background-primary: #421b79;
  --background-secondary: #251946;
  --hr: rgba(255, 255, 255, 0.15);

  --font-family: "Quicksand", sans-serif;
}

body {
  background-color: var(--background-secondary);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

svg {
  margin-inline-end: 0 !important;
  color: #ffffff;
}

* > svg + * {
  margin-inline-start: 0.5rem;
}

a,
button {
  display: flex !important;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

* {
  font-family: var(--font-family) !important;
}
