.header {
  > div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;

    > div {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;

      > h1 {
        font-size: 1.75rem;
        margin: 0 !important;
        margin-top: 0.5rem !important;
      }

      > span:first-child {
        font-size: 1rem;
        font-weight: 600;
        color: var(--secondary);
      }

      > span:last-child {
        margin-top: 1.15rem;
        text-align: center;
      }
    }

    &:before,
    &:after {
      content: none !important;
    }
  }
}

.rewards {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 0.5rem;

  .card {
    > div {
      padding: 1.25rem;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        margin-inline-end: -1rem;

        > div:first-child {
          display: flex;
          align-items: center;
        }
      }

      button {
        flex: 0 0 auto;
        font-size: 1.25rem;
        margin-inline-end: 0.5rem;
      }

      &:before,
      &:after {
        content: none;
      }
    }
  }
}
