.container {
  padding: 0;
  width: 40rem;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  border: none;
  border-radius: 1rem;
  background: transparent;

  .header {
    flex: 0 0 auto;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-secondary);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-bottom: solid 1px var(--hr);

    > h1 {
      margin: 0;
      font-size: 1rem;
    }
  }

  .content {
    flex: 1 1 auto;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
