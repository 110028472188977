.container {
  height: 100%;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 2rem;

  .header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;

    span {
      font-size: 1.25rem;
    }

    h5,
    span {
      padding-inline-start: 0.5rem;
    }
  }

  .content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 0.5rem;

    > h5 {
      color: var(--secondary);
      padding-inline-start: 0.5rem;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .reward {
        flex: 0 0 32%;

        > div {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          justify-content: space-between;

          &::before,
          &::after {
            content: none;
          }

          > div {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
  }
}
