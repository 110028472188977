.content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: var(--background-primary);

  > div {
    flex: 0 0 auto;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    background-color: var(--background-primary);

    > svg {
      margin-inline-start: 1rem;
      flex: 0 0 auto;
      color: var(--text-secondary);
    }

    > input {
      flex: 1 1 auto;
      height: 3rem;
      border: 0;
      outline: none;
      padding-inline-end: 1rem;
      color: var(--text);
      background-color: transparent;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  > ul {
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    list-style: none;

    > li:first-child {
      border-top: solid 1px var(--hr);
    }

    > li > button {
      width: 100%;
      padding: 1rem;
      height: 4.4rem;
      border-radius: 0;

      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > div:first-child {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;

        > span:last-child {
          font-size: 0.66rem;
        }
      }

      > div:last-child {
        > span {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  > button {
    padding: 1rem;
    border-radius: 0;
    height: 3.4rem;
    border-top: solid 1px var(--hr);
  }
}
